<template>
  <div class="ma-4">
    <v-card class="ma-4">
      <v-toolbar color="primary">
        <v-icon color="white" large class="mr-2">mdi-format-list-text</v-icon>
        <v-toolbar-title class="white--text">
          {{ $t("planner-mng-title") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <MessageBox ref="msgBox" />
        <ConvalidateShift
          ref="convalidateShift"
          v-model="convalidateShiftDialog"
          @shiftConfigured="onConvalidateShiftConfigured"
        />
        <EditEffectiveShift
          ref="editShift"
          v-model="editShiftDialog"
          @shiftConfigured="shiftConfigured"
        />
        <v-row dense>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-worker')"
              outlined
              item-text="name"
              item-value="id"
              dense
              hide-details
              :items="flt_operators"
              v-model="filters.flt_operator"
            ></v-select
          ></v-col>

          <v-col>
            <v-select
              :label="$t('planner-mng-selected-customer')"
              outlined
              item-text="name"
              item-value="id"
              dense
              hide-details
              :items="flt_customers"
              v-model="filters.flt_customer"
            ></v-select
          ></v-col>

          <v-col>
            <v-select
              outlined
              dense
              :label="$t('logs-mngs-period')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-week')"
              outlined
              item-text="week_name"
              item-value="id"
              dense
              hide-details
              :items="flt_weeks"
              v-model="filters.flt_week"
              :disabled="loadingFilters || filters.filterByPeriod == 14"
            ></v-select>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="filters.filterByPeriod != 13"
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('logs-mngs-datestart')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="filters.filterByPeriod != 13"
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('logs-mngs-dateend')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row dense justify="center" align="center">
          <v-col>
            <v-btn
              :disabled="loadingFilters || loadingShifts"
              color="secondary"
              class="mr-1"
              @click="exportShiftsToInfinity"
              ><v-icon right dark>mdi-file-excel-outline</v-icon
              ><span class="ml-1">{{
                $t("customers-mng-export-infinity")
              }}</span>
            </v-btn>
            <v-btn
              :disabled="loadingFilters || loadingShifts"
              color="secondary"
              class="mr-1"
              @click="exportShiftsToExcel"
              ><v-icon right dark>mdi-file-excel-outline</v-icon
              ><span class="ml-1">{{ $t("customers-mng-export-excel") }}</span>
            </v-btn>
            <v-btn
              class="ml-1"
              :disabled="loadingFilters || loadingShifts"
              color="secondary"
              @click="exportShiftsToPdf"
              ><v-icon right dark>mdi-image-filter-tilt-shift</v-icon
              ><span class="ml-1">{{ $t("customers-mng-export-pdf") }}</span>
            </v-btn>
            <v-btn
              class="ml-1"
              :disabled="loadingFilters || loadingShifts"
              color="secondary"
              @click="exportShiftsToCsv"
              ><v-icon right dark>mdi-image-filter-tilt-shift</v-icon
              ><span class="ml-1">{{ $t("customers-mng-export-csv") }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-progress-circular
              v-if="loadingShifts"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-row dense v-if="!loadingShifts">
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="secondary"
              icon="mdi-account-tie"
              title="Clienti"
              :value="getTotalCustomers"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="secondary"
              icon="mdi-account-tie"
              title="Operatori"
              :value="getTotalOperators"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="primary"
              icon="mdi-calendar"
              title="Ore totali"
              :value="getTotalHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="primary"
              icon="mdi-calendar"
              title="Ore ordinarie"
              :value="getRegularHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="secondary"
              icon="mdi-calendar"
              title="Ore prefestivo"
              :value="getPreHolidayHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="warning"
              icon="mdi-calendar"
              title="Ore festivo"
              :value="getHolidayHours"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              class="grid-table"
              :items-per-page="perPage"
              :must-sort="true"
              :headers="headers"
              :items="shifts"
              :options.sync="options"
              :server-items-length="totalShifts"
              :loading="loadingShifts"
              :footer-props="dataTableFooterOptions"
              :item-class="itemRowBackground"
            >
              <template v-slot:item.operator_last_name="{ item }">
                {{ item.operator_first_name + " " + item.operator_last_name }}
              </template>

              <template v-slot:item.shift_day="{ item }">
                <span>{{ dayToName(item.day) }}</span>
              </template>
              <template v-slot:item.shift="{ item }">
                <span>{{
                  stripeSeconds(item.shift_start_time) +
                  " - " +
                  stripeSeconds(item.shift_end_time)
                }}</span>
              </template>
              <template v-slot:item.duration="{ item }">
                <span>{{ formatDuration(item.duration) }}</span>
              </template>

              <template v-slot:item.effective_shift="{ item }">
                <span v-if="item.effective_shift_id && item.void">{{
                  $t("planner-mng-shift-void")
                }}</span>
                <span v-if="item.effective_shift_id && !item.void">{{
                  stripeSeconds(item.effective_shift_start_time) +
                  " - " +
                  stripeSeconds(item.effective_shift_end_time)
                }}</span
                ><v-tooltip v-if="item.effective_shift_id && !item.void" bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      class="ml-2"
                      x-small
                      text
                      icon
                      color="primary"
                      @click.stop="changeShiftTimes(item)"
                      v-on="on"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{ $t("planner-mng-edit-shift") }}</span></v-tooltip
                >
              </template>
              <template v-slot:item.business_name="{ item }">
                {{
                  item.addr_business_name
                    ? item.addr_business_name
                    : item.addr_first_name + " " + item.addr_last_name
                }}
              </template>

              <template v-slot:item.actions="{ item }">
                <span>
                  <v-tooltip
                    v-if="!item.effective_shift_id && !item.void"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="purple"
                        @click.stop="voidShift(item)"
                        v-on="on"
                        ><v-icon>mdi-account-off-outline</v-icon></v-btn
                      ></template
                    ><span>{{ $t("planner-mng-void-shift") }}</span></v-tooltip
                  >

                  <v-tooltip v-if="item.void" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="purple"
                        @click.stop="unvoidShift(item)"
                        v-on="on"
                        ><v-icon>mdi-account-reactivate-outline</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("planner-mng-unvoid-shift")
                    }}</span></v-tooltip
                  >
                </span>
                <span>
                  <v-tooltip
                    v-if="!item.effective_shift_id && !item.void"
                    bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="green"
                        @click.stop="convalidateItem(item)"
                        v-on="on"
                        ><v-icon>mdi-account-clock-outline</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("planner-mng-convalidate-shift")
                    }}</span></v-tooltip
                  >

                  <v-tooltip v-if="item.effective_shift_id && !item.void" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        x-small
                        text
                        icon
                        color="red"
                        @click.stop="invalidateItem(item)"
                        v-on="on"
                        ><v-icon>mdi-timer-minus-outline</v-icon></v-btn
                      ></template
                    ><span>{{
                      $t("planner-mng-invalidate-shift")
                    }}</span></v-tooltip
                  >
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import plannerManager from "../apis/planner";
import staticData from "../apis/static";
import usersManager from "../apis/users";
import { Duration } from "luxon";
import MessageBox from "@/components/MessageBox.vue";
import ConvalidateShift from "@/components/ConvalidateShift.vue";
import EditEffectiveShift from "@/components/EditEffectiveShift.vue";

export default {
  data() {
    return {
      date_start_modal: false,
      date_end_modal: false,
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      options: {
        sortBy: ["day_date", "operator_last_name"],
        sortDesc: [false, false],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      convalidateShiftDialog: null,
      editShiftDialog: null,
      staticData: staticData,
      loadingFilters: false,
      loadingShifts: false,
      shifts: [],
      totalShifts: 0,
      statistics: null,
      flt_weeks: [
        {
          id: null,
          week_name: "Seleziona una settimana",
          disabled: true,
        },
      ],

      flt_operators: [
        {
          id: null,
          name: this.$t("gbl-all-male"),
        },
      ],

      flt_customers: [
        {
          id: null,
          name: this.$t("gbl-all-male"),
        },
      ],

      filters: {
        flt_customer: null,
        flt_operator: null,
        flt_week: null,
        filterByPeriod: 15,
        filterByCustomDateEnd: null,
        filterByCustomDateStart: null,
      },
    };
  },

  components: {
    MessageBox,
    ConvalidateShift,
    EditEffectiveShift,
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("planner-tbl-week"),
        align: "left",
        sortable: true,
        value: "week_name",
      },
      {
        text: this.$t("planner-tbl-day"),
        align: "left",
        sortable: true,
        value: "day_date",
      },

      {
        text: this.$t("planner-tbl-name"),
        align: "center",
        sortable: true,
        value: "operator_last_name",
      },
      {
        text: this.$t("planner-tbl-customer"),
        align: "center",
        sortable: true,
        value: "business_name",
      },
      {
        text: this.$t("planner-tbl-address"),
        align: "center",
        sortable: true,
        value: "addr_address",
      },
      {
        text: this.$t("planner-tbl-day"),
        align: "center",
        sortable: true,
        value: "shift_day",
      },
      {
        text: this.$t("planner-tbl-shift-time"),
        align: "center",
        sortable: true,
        value: "shift",
      },
      {
        text: this.$t("planner-tbl-effective-shift-time"),
        align: "center",
        sortable: true,
        value: "effective_shift",
      },
      {
        text: this.$t("planner-tbl-duration"),
        align: "center",
        sortable: true,
        value: "duration",
      },
      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  watch: {
    "filters.filterByPeriod": {
      handler: function () {
        if (
          this.filters.flt_operator != null ||
          this.filters.flt_week != null
        ) {
          this.filters.flt_operator = null;
          this.filters.flt_week = null;
        } else {
          this.fetchPlannerShifts();
        }
      },
    },

    "filters.filterByCustomDateEnd": {
      handler: function () {
        if (
          this.filters.flt_operator != null ||
          this.filters.flt_week != null
        ) {
          this.filters.flt_operator = null;
          this.filters.flt_week = null;
        } else {
          this.fetchPlannerShifts();
        }
      },
    },

    "filters.filterByCustomDateStart": {
      handler: function () {
        if (
          this.filters.flt_operator != null ||
          this.filters.flt_week != null
        ) {
          this.filters.flt_operator = null;
          this.filters.flt_week = null;
        } else {
          this.fetchPlannerShifts();
        }
      },
    },

    "filters.flt_week": {
      handler: function () {
        this.fetchPlannerShifts();
      },
    },

    "filters.flt_operator": {
      handler: function () {
        this.fetchPlannerShifts();
      },
    },

    "filters.flt_customer": {
      handler: function () {
        this.fetchPlannerShifts();
      },
    },

    options: {
      handler: function () {
        this.fetchPlannerShifts();
      },
    },
  },

  methods: {
    shiftConfigured(item) {
      this.editShiftDialog = false;
      let start_time = item.shift.shift_start_time
        .split(":")
        .slice(0, 2)
        .join(":");
      let end_time = item.shift.shift_end_time.split(":").slice(0, 2).join(":");

      plannerManager
        .changeShiftEffectiveTimes(item.shift.id, start_time, end_time)

        .then(() => {
          this.fetchPlannerShifts();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    itemRowBackground: (item) => {
      if (item.void) return "bg-warning";
    },

    changeShiftTimes(item) {
      let shift = { ...item };
      shift.shift_start_time = item.effective_shift_start_time;
      shift.shift_end_time = item.effective_shift_end_time;

      this.$refs.editShift.setShift(shift);
      this.editShiftDialog = true;
    },

    formatDuration: (duration) => {
      if (duration) {
        return Duration.fromObject({
          seconds: duration,
        }).toFormat("hh:mm");
      }
      return "--:--:--";
    },

    stripeSeconds: (time) => {
      if (time) return time.split(":").slice(0, 2).join(":");
      return null;
    },

    getPeriodFilters: () => {
      return staticData.statisticsPeriods;
    },
    dayToName(day) {
      return day >= 0 && day <= 6 ? this.staticData.days[day].name : "";
    },

    exportShiftsToExcel() {
      usersManager
        .keepAlive()
        .then(() => {
          plannerManager
            .exportShiftsToExcel()
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    exportShiftsToInfinity() {
      usersManager
        .keepAlive()
        .then(() => {
          plannerManager
            .exportShiftsToInfinity()
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    exportShiftsToPdf() {
      usersManager
        .keepAlive()
        .then(() => {
          plannerManager
            .exportShiftsToPdf()
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    exportShiftsToCsv() {
      usersManager
        .keepAlive()
        .then(() => {
          plannerManager
            .exportShiftsToCsv()
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    voidShift(item) {
      this.$refs.msgBox
        .show(
          this.$t("planner-mng-void-shift-text"),
          this.$t("planner-mng-void-shift-title")
        )
        .then(() => {
          plannerManager
            .voidShift(item.id)
            .then(() => {
              this.fetchPlannerShifts();
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },

    unvoidShift(item) {
      this.$refs.msgBox
        .show(
          this.$t("planner-mng-unvoid-shift-text"),
          this.$t("planner-mng-unvoid-shift-title")
        )
        .then(() => {
          plannerManager
            .unvoidShift(item.id)
            .then(() => {
              this.fetchPlannerShifts();
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },

    onConvalidateShiftConfigured(item) {
      plannerManager
        .convalidateShift(
          item.item.id,
          item.shift.shift_start_time,
          item.shift.shift_end_time
        )
        .then(() => {
          this.convalidateShiftDialog = false;
          this.fetchPlannerShifts();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    convalidateItem(item) {
      this.convalidateShiftDialog = true;
      this.$refs.convalidateShift.setItem(item);
    },

    invalidateItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("planner-mng-invalidate-shift-text"),
          this.$t("planner-mng-invalidate-shift-title")
        )
        .then(() => {
          plannerManager
            .invalidateShift(item.id)
            .then(() => {
              this.fetchPlannerShifts();
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },

    fetchPlannerShifts() {
      return new Promise((resolve, reject) => {
        this.loadingShifts = true;
        let ctx = { ...this.filters, ...this.options };

        plannerManager
          .fetchPlannerShifts(ctx)
          .then((res) => {
            this.shifts = res.shifts;
            this.totalShifts = res.totalShifts;
            for (let x of res.operators) {
              x.name = x.operator_first_name + " " + x.operator_last_name;
            }
            for (let x of res.customers) {
              x.name = x.addr_business_name
                ? x.addr_business_name
                : x.addr_first_name + " " + x.addr_last_name;
            }
            this.flt_operators = [
              { id: null, name: this.$t("gbl-all-male") },
              ...res.operators,
            ];
            this.flt_customers = [
              { id: null, name: this.$t("gbl-all-male") },
              ...res.customers,
            ];

            this.flt_weeks = [
              {
                id: null,
                week_name: "Seleziona una settimana",
                disabled: true,
              },
              ...res.weeks,
            ];

            this.statistics = res.statistics;
            this.loadingShifts = false;
            resolve();
          })
          .catch((err) => {
            this.loadingShifts = false;
            console.log(err);
            reject(err);
          });
      });
    },
  },

  computed: {
    getTotalCustomers() {
      if (!this.statistics) return "--:--:--";
      return this.statistics.customers_count.toString();
    },

    getTotalOperators() {
      if (!this.statistics) return "--:--:--";
      return this.statistics.operators_count.toString();
    },

    getTotalHours() {
      if (!this.statistics) return "--:--:--";
      return Duration.fromObject({
        seconds: this.statistics.total_time,
      }).toFormat("d(g) hh:mm");
    },

    getRegularHours() {
      if (!this.statistics) return "--:--:--";
      return Duration.fromObject({
        seconds:
          this.statistics.total_time -
          this.statistics.pre_holiday_time -
          this.statistics.holiday_time,
      }).toFormat("d(g) hh:mm");
    },

    getPreHolidayHours() {
      if (!this.statistics) return "--:--:--";
      return Duration.fromObject({
        seconds: this.statistics.pre_holiday_time,
      }).toFormat("d(g) hh:mm");
    },

    getHolidayHours() {
      if (!this.statistics) return "--:--:--";
      return Duration.fromObject({
        seconds: this.statistics.holiday_time,
      }).toFormat("d(g) hh:mm");
    },

    start_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
    },

    end_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
    },
  },
};
</script>

<style>
.bg-warning {
  background-color: #f7119b !important;
  color: white;
}
</style>
