<template>
  <div>
    <messageBox ref="msgBox" />
    <CustomerEditor
      ref="customerEditor"
      @customerConfigured="handleCustomer"
      v-model="customerEditorDialog"
    />

    <customersCategoryEditor
      @categoryEditingDone="handleCategoryEditingDone"
      ref="categoryEditor"
      v-model="categoryEditorDialog"
    />

    <v-card class="ma-4">
      <v-toolbar color="primary">
        <v-icon color="white" large class="mr-2">mdi-account-group</v-icon>
        <v-toolbar-title class="white--text">{{
          $t("customers-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height: 10px" />
      </div>

      <v-row v-if="$store.state.hasRight('23')" class="text-center">
        <v-col>
          <v-btn
            :disabled="fetchingData"
            color="primary"
            @click.stop="addCustomer()"
            >{{ $t("customers-add-customer") }}</v-btn
          >

          <v-btn
            v-if="$store.state.hasRight('24')"
            :disabled="fetchingData"
            v-on:click="manageCategory(0)"
            color="primary"
            >{{ $t("customers-mng-category") }}</v-btn
          >

          <v-menu rounded="lg" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-if="$store.state.hasRight('24')"
                :disabled="fetchingData"
                color="secondary"
                v-bind="attrs"
                v-on="on"
                ><v-icon right dark>mdi-file-excel-outline</v-icon
                ><span class="ml-1">{{
                  $t("customers-mng-export-excel")
                }}</span>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title v-on:click="exportExcel(false)">{{
                  $t("customers-mng-export-list")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title v-on:click="exportExcel(true)">{{
                  $t("customers-mng-export-contacts")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu rounded="lg" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-if="$store.state.hasRight('24')"
                :disabled="fetchingData"
                color="secondary"
                v-bind="attrs"
                v-on="on"
                ><v-icon right dark>mdi-file-table-outline</v-icon
                ><span class="ml-1">{{ $t("customers-mng-export-csv") }}</span>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title v-on:click="exportCSV(false)">{{
                  $t("customers-mng-export-list")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title v-on:click="exportCSV(true)">{{
                  $t("customers-mng-export-contacts")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <div style="margin: 15px">
        <v-row justify="center">
          <v-col>
            <v-autocomplete
              :disabled="fetchingData"
              clearable
              dense
              no-filter
              v-model="flt_customer"
              :items="flt_customers"
              :loading="isLoadingCustomer"
              :search-input.sync="searchCustomerInput"
              outlined
              item-text="display_name"
              item-value="id"
              :label="$t('customers-filter-customer')"
              return-object
            ></v-autocomplete>
          </v-col>
        </v-row>
      </div>

      <v-data-table
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="customers"
        :options.sync="options"
        :server-items-length="totalCustomers"
        :loading="fetchingData"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.customer_color="{ item }">
          <v-icon :color="item.reference_color">mdi-circle</v-icon>
        </template>

        <template v-slot:item.last_order_date="{ item }">
          {{ item.last_order_date | toLocaleDate }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div style="min-width: 120px">
            <v-tooltip
              v-if="!item.enabled && $store.state.hasRight('24')"
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="banCustomer(item, false)"
                  v-on="on"
                  ><v-icon>mdi-account-off</v-icon></v-btn
                ></template
              ><span>{{ $t("customers-unban") }}</span></v-tooltip
            >
            <v-tooltip v-if="item.enabled && $store.state.hasRight('24')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="success"
                  @click.stop="banCustomer(item, true)"
                  v-on="on"
                  ><v-icon>mdi-account</v-icon></v-btn
                ></template
              ><span>{{ $t("customers-ban") }}</span></v-tooltip
            >
            <v-tooltip v-if="$store.state.hasRight('24')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="gray"
                  @click.stop="editCustomer(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>{{ $t("customer-action-edit") }}</span></v-tooltip
            >

            <v-tooltip
              v-if="$store.state.hasRight('25') && item.num_orders == 0"
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteCustomer(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("customer-action-delete") }}</span></v-tooltip
            >
            <v-tooltip
              v-if="!$store.state.hasRight('25') || item.num_orders > 0"
              bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  :disabled="true"
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteCustomer(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("customer-action-delete") }}</span></v-tooltip
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import customersManager from "../apis/customers";
import usersManager from "../apis/users";
import staticData from "../apis/static";
import config from "../config";

import messageBox from "../components/MessageBox";
import CustomerEditor from "../components/CustomerEditor";
import customersCategoryEditor from "../components/CustomersCategoryEditor";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      customers: [],
      totalCustomers: 0,
      perPage: staticData.defaultPerPage,
      filter: null,
      filters: {
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        customer: null,
      },

      options: {
        sortBy: ["business_name"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },

      headers: [],

      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,
      categoryEditorDialog: null,
      fetchingData: false,
      customerEditorDialog: false,
    };
  },

  components: {
    messageBox,
    CustomerEditor,
    customersCategoryEditor,
  },

  mounted() {
    this.headers = [
      {
        text: null,
        align: "center",
        sortable: false,
        value: "customer_color",
      },
      {
        text: this.$t("customers-tbl-business-name"),
        align: "center",
        sortable: true,
        value: "business_name",
      },
      {
        text: this.$t("customers-tbl-name"),
        align: "center",
        sortable: true,
        value: "first_name",
      },
      {
        text: this.$t("customers-tbl-last-name"),
        align: "center",
        sortable: true,
        value: "last_name",
      },
      {
        text: this.$t("customers-tbl-email"),
        align: "center",
        sortable: true,
        value: "email",
      },

      {
        text: this.$t("customers-tbl-num-addresses"),
        align: "center",
        sortable: true,
        value: "num_addresses",
      },

      { text: "", align: "right", sortable: false, value: "actions" },
    ];
    this.fetchCategories();
  },

  watch: {
    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;
          this.fetchFilterCustomers(val)
            .then((res) => {
              this.flt_customers = res.customers.map((x) => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.filters.customer = null;
      }
    },

    flt_customers: {
      handler() {
        this.filters.customer = this.flt_customer ? this.flt_customer.id : null;
      },
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchCustomers();
        }, config.searchInputsFastTimeout);
      },
      deep: true,
    },

    filters: {
      handler() {
        this.customers = [];
        this.totalCustomers = 0;

        clearTimeout(this._debounceTimer);
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true],
        };
      },

      deep: true,
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["ID"],
          sortDesc: [true],
        };
      },
    },
  },

  methods: {
    manageCategory() {
      this.categoryEditorDialog = true;
    },

    handleCategoryEditingDone() {
      this.fetchCategories();
    },

    fetchCategories() {
      this.categories = [{ name: this.$t("gbl-all-female"), id: null }];
      this.loadingData = true;
      let ctx = {};
      ctx.sortBy = "name";
      ctx.sortDesc = false;
      customersManager
        .fetchCategories(ctx)
        .then((results) => {
          this.categories = [...this.categories, ...results.items];
          this.loadingData = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingData = false;
        });
    },

    handleCustomer(event) {
      if (event.editingCustomer) {
        let customer = event.customer;
        customersManager
          .updateCustomer(customer)
          .then(() => {
            this.fetchCustomers();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let customer = event.customer;
        customersManager
          .addCustomer(customer)
          .then(() => {
            this.fetchCustomers();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.customerEditorDialog = false;
    },

    addCustomer() {
      this.$refs.customerEditor.setItem(false, {
        reference_color: null,
      });
      this.customerEditorDialog = true;
    },

    editCustomer(item) {
      this.$refs.customerEditor.setItem(true, item);
      this.customerEditorDialog = true;
    },

    banCustomer(item, state) {
      customersManager
        .banCustomer(item.id, state)
        .then(() => {
          this.fetchCustomers();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onCustomerConfigured(res) {
      if (res.editingCustomer) {
        customersManager
          .editCustomer(res.item)
          .then(() => {
            this.fetchCustomers();
            this.customerEditorDialog = false;
          })
          .catch((err) => {
            console.log(err);
            this.customerEditorDialog = false;
          });
      } else {
        customersManager
          .addCustomer(res.item)
          .then(() => {
            this.fetchCustomers();
            this.customerEditorDialog = false;
          })
          .catch((err) => {
            console.log(err);
            this.customerEditorDialog = false;
          });
      }
    },

    deleteCustomer(item) {
      this.$refs.msgBox
        .show(
          this.$t("customer-delete-confirm"),
          this.$t("customer-delete-title")
        )
        .then(() => {
          customersManager
            .removeCustomer(item.id)
            .then(() => {
              this.fetchCustomers();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchCustomers: function () {
      this.fetchingData = true;
      this.customers = [];
      this.totalCustomers = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.filters = this.filters;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      customersManager
        .fetchCustomers(ctx)
        .then((results) => {
          this.fetchingData = false;
          this.customers = results.customers;
          this.totalCustomers = results.count;
        })
        .catch((err) => {
          this.fetchingData = false;
          console.log(err);
        });
    },

    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    exportExcel(contacts) {
      usersManager
        .keepAlive()
        .then(() => {
          customersManager
            .downloadRegistryExcel(contacts)
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    exportCSV(contacts) {
      usersManager
        .keepAlive()
        .then(() => {
          customersManager
            .downloadRegistryCSV(contacts)
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
