<template>
  <div class="ma-4">
    <v-card class="ma-4">
      <v-toolbar color="primary">
        <v-icon color="white" large class="mr-2">mdi-format-list-text</v-icon>
        <v-toolbar-title class="white--text">
          {{ $t("planner-mng-title") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <EditShift
          ref="editShift"
          v-model="editShiftDialog"
          @shiftConfigured="shiftConfigured"
        />
        <AddPlannerShift
          ref="addShift"
          v-model="addShiftDialog"
          @shiftConfigured="onAddShift"
        />
        <SetString ref="setString" />
        <MessageBox ref="msgBox" />
        <SetTemplate ref="setTemplate" />

        <v-row dense>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-week')"
              outlined
              :disabled="loadingFilters"
              item-text="name"
              item-value="id"
              dense
              hide-details
              :items="weeks"
              v-model="selectedWeek"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-week-mode')"
              outlined
              :disabled="loadingFilters"
              item-text="name"
              item-value="id"
              dense
              hide-details
              :items="weeksModes"
              v-model="selectedWeeksMode"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-week-time')"
              outlined
              :disabled="loadingFilters"
              item-text="name"
              item-value="id"
              dense
              hide-details
              :items="weeksPeriod"
              v-model="selectedWeeksPeriod"
            ></v-select>
          </v-col>

          <v-col>
            <v-autocomplete
              :disabled="
                loadingPlanner ||
                updatingPlanner ||
                loadingFilters ||
                !this.selectedWeek
              "
              clearable
              dense
              no-filter
              v-model="flt_user"
              :items="flt_users"
              :loading="isLoadingUser"
              :search-input.sync="searchUserInput"
              outlined
              item-text="name"
              item-value="id"
              :label="$t('customers-filter-user')"
              return-object
              hide-details
              ><template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <div class="v-list-item__title">{{ data.item.name }}</div>
                <v-icon v-if="data.item.shifts_count > 0" color="green"
                  >mdi-circle</v-icon
                >
                <v-icon
                  v-if="data.item.exceptions && data.item.exceptions.length > 0"
                  color="orange"
                  >mdi-bed-clock</v-icon
                >
              </template></v-autocomplete
            >
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col>
            <v-btn
              :disabled="
                loadingFilters ||
                !selectedWeek ||
                loadingPlanner ||
                updatingPlanner
              "
              color="secondary"
              @click="exportWeekToExcel"
              ><v-icon right dark>mdi-file-excel-outline</v-icon
              ><span class="ml-1">{{ $t("customers-mng-export-excel") }}</span>
            </v-btn>
            <v-btn
              :disabled="
                loadingFilters ||
                !selectedWeek ||
                loadingPlanner ||
                updatingPlanner
              "
              @click="saveTemplate"
              color="secondary"
              ><v-icon right dark>mdi-database-import</v-icon
              ><span class="ml-1">{{ $t("planner-mng-create-template") }}</span>
            </v-btn>

            <v-btn
              :disabled="
                loadingFilters ||
                !selectedWeek ||
                loadingPlanner ||
                updatingPlanner
              "
              color="secondary"
              @click="loadTemplate"
              ><v-icon right dark>mdi-database-export</v-icon
              ><span class="ml-1">{{ $t("planner-mng-load-template") }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-progress-circular
              v-if="loadingPlanner"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-row dense v-if="!loadingPlanner && selectedWeek && flt_user">
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="primary"
              icon="mdi-calendar"
              title="Ore totali"
              :value="getTotalHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="primary"
              icon="mdi-calendar"
              title="Ore ordinarie"
              :value="getRegularHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="secondary"
              icon="mdi-calendar"
              title="Ore prefestivo"
              :value="getPreHolidayHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="warning"
              icon="mdi-calendar"
              title="Ore festivo"
              :value="getHolidayHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="warning"
              icon="mdi-calendar"
              title="Giorni"
              :value="getTotalDays"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="success"
              icon="mdi-signature-freehand"
              title="Ore da contratto"
              :value="getContractHours"
            />
          </v-col>
        </v-row>

        <v-row v-if="!loadingPlanner && selectedWeek && flt_user">
          <v-col v-for="day in weekDays" :key="day.day">
            <v-card>
              <v-toolbar
                class="pt-1"
                dense
                :color="hasException(day) ? 'error' : 'primary'"
              >
                <v-toolbar-title
                  style="width: 100%; margin: auto"
                  class="white--text"
                >
                  <v-icon class="mr-1 mb-1" v-if="day.holiday" color="orange"
                    >mdi-weather-sunny</v-icon
                  ><v-icon
                    class="mr-1 mb-1"
                    v-if="day.pre_holiday && !day.holiday"
                    color="purple"
                    >mdi-weather-sunny</v-icon
                  >{{ day.name }}

                  <v-tooltip v-if="hasException(day)" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-icon class="ml-2 mb-2" color="orange" v-on="on"
                        >mdi-bed-clock</v-icon
                      > </template
                    ><span>{{
                      hasException(day).reason_value
                    }}</span></v-tooltip
                  >

                  <v-menu
                    v-model="day.menu"
                    :close-on-content-click="false"
                    offset-y
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="red" dark icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-bed</v-icon>
                      </v-btn>
                    </template>

                    <v-list v-if="!hasException(day)">
                      <v-list-item :key="item.id" v-for="item in reasons">
                        <v-list-item-title
                          @click="addException(day, item.id)"
                          >{{ item.name }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                    <v-list v-if="hasException(day)">
                      <v-list-item>
                        <v-list-item-title @click="removeException(day)"
                          >Rimuovi eccezione</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar-title>
              </v-toolbar>

              <draggable
                animation="0"
                @change="onChange($event, day.array)"
                v-model="day.array"
                group="shifts"
              >
                <v-sheet
                  :color="getSheetColor(element)"
                  elevation="1"
                  class="ma-2"
                  v-for="(element, index) in day.array"
                  :key="element.id"
                >
                  <v-container :style="'color: ' + getSheetTextColor(element)">
                    <v-row dense>
                      <v-col
                        ><span class="font-weight-bold">{{
                          element.name
                        }}</span>
                        <div class="font-weight-bold purple--text">
                          {{ element.notes }}
                          <v-tooltip bottom
                            ><template v-slot:activator="{ on }"
                              ><v-btn
                                dark
                                class="toolbar-icon"
                                v-on="on"
                                @click="editNotes(element)"
                                icon
                                href="#"
                                color="orange"
                                ><v-icon>mdi-pencil</v-icon></v-btn
                              ></template
                            ><span>{{
                              $t("planner-mng-edit-notes")
                            }}</span></v-tooltip
                          >
                        </div>

                        <v-tooltip v-if="element.shift_exception" bottom
                          ><template v-slot:activator="{ on }"
                            ><v-icon class="ml-2" color="orange" v-on="on"
                              >mdi-bed-clock</v-icon
                            > </template
                          ><span>{{
                            element.shift_exception.reason_value
                          }}</span></v-tooltip
                        >

                        <v-tooltip v-if="!element.flexible_day" bottom
                          ><template v-slot:activator="{ on }"
                            ><v-icon class="ml-1" color="orange" v-on="on"
                              >mdi-lock</v-icon
                            > </template
                          ><span>{{
                            $t("planner-mng-day-locked")
                          }}</span></v-tooltip
                        >
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-icon>mdi-google-maps</v-icon
                        >{{ element.address }}</v-col
                      >
                    </v-row>
                    <v-row dense>
                      <v-col v-if="element.day != day.day" style="color: red"
                        ><v-icon>mdi-calendar</v-icon
                        >{{ dayToName(element.day) }}</v-col
                      ></v-row
                    >
                    <v-row dense>
                      <v-col
                        ><v-icon>mdi-account</v-icon>
                        {{
                          $t("planner-mng-operator", {
                            operator: element.operator,
                          })
                        }}</v-col
                      >
                    </v-row>

                    <v-row dense>
                      <v-col
                        :style="
                          updatingPlanner
                            ? 'color:gray'
                            : 'color:' + getSheetTextColor(element)
                        "
                        ><v-icon>mdi-clock</v-icon
                        >{{ element.shift_start_time.toFormat("HH:mm") }} -
                        {{ element.shift_end_time.toFormat("HH:mm") }}</v-col
                      >
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              :disabled="
                                updatingPlanner || !element.flexible_time
                              "
                              dark
                              class="toolbar-icon"
                              v-on="on"
                              @click="changeShiftTimes(element, day.array)"
                              icon
                              href="#"
                              color="orange"
                              ><v-icon>mdi-pencil</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("planner-mng-change-times")
                          }}</span></v-tooltip
                        ><v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              :disabled="
                                updatingPlanner || !element.flexible_time
                              "
                              dark
                              class="toolbar-icon"
                              v-on="on"
                              @click="splitShift(element, day.array)"
                              icon
                              href="#"
                              color="purple"
                              ><v-icon
                                >mdi-arrow-split-horizontal</v-icon
                              ></v-btn
                            ></template
                          ><span>{{
                            $t("planner-mng-split-shift")
                          }}</span></v-tooltip
                        ><v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              :disabled="
                                updatingPlanner || !element.flexible_time
                              "
                              dark
                              class="toolbar-icon"
                              v-on="on"
                              @click="duplicateShift(element, day.array)"
                              icon
                              href="#"
                              color="purple"
                              ><v-icon>mdi-arrow-split-vertical</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("planner-mng-duplicate-shift")
                          }}</span></v-tooltip
                        ><v-tooltip
                          v-if="
                            index != 0 &&
                            day.array[index - 1].customer_id ==
                              day.array[index].customer_id &&
                            day.array[index - 1].address_id ==
                              day.array[index].address_id
                          "
                          bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              :disabled="
                                updatingPlanner || !element.flexible_time
                              "
                              dark
                              class="toolbar-icon"
                              v-on="on"
                              @click="mergeShifts(element, day.array)"
                              icon
                              href="#"
                              color="purple"
                              ><v-icon>mdi-table-merge-cells</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("planner-mng-merge-shift")
                          }}</span></v-tooltip
                        ><v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              :disabled="updatingPlanner"
                              dark
                              class="toolbar-icon"
                              v-on="on"
                              @click="putBackInUnassigned(element.id)"
                              icon
                              color="green"
                              ><v-icon>mdi-arrow-right</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("planner-mng-put-back")
                          }}</span></v-tooltip
                        >
                      </v-col> </v-row
                    ><v-row v-if="element.updating" dense>
                      <v-col>
                        <v-progress-circular
                          :indeterminate="true"
                          :rotate="0"
                          :size="16"
                          :width="2"
                          color="primary"
                        ></v-progress-circular>
                      </v-col> </v-row
                  ></v-container>
                </v-sheet>
              </draggable>
              <v-sheet color="secondary" class="white--text body-1">
                {{ totalDurationOfArray(day.array) }}</v-sheet
              >
            </v-card>
          </v-col>
          <v-col :key="unassignedDay.id">
            <v-card class="ma-4">
              <v-row dense class="ma-1">
                <v-col cols="10">
                  <v-autocomplete
                    :disabled="
                      loadingPlanner || updatingPlanner || loadingFilters
                    "
                    clearable
                    dense
                    no-filter
                    v-model="flt_customer"
                    :items="flt_customers"
                    :loading="isLoadingCustomer"
                    :search-input.sync="searchCustomerInput"
                    outlined
                    item-text="name"
                    item-value="id"
                    :label="$t('customers-filter-customer')"
                    return-object
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="1">
                  <v-tooltip v-if="flt_customer" bottom
                    ><template v-slot:activator="{ on }"
                      ><v-btn
                        dark
                        v-on="on"
                        @click="createShift(flt_customer)"
                        icon
                        color="green"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      ></template
                    ><span>{{ $t("planner-mng-add-shift") }}</span></v-tooltip
                  >
                </v-col>
                <v-col cols="12">
                  <v-select
                    :disabled="
                      loadingPlanner ||
                      updatingPlanner ||
                      loadingFilters ||
                      isLoadingAddress ||
                      !flt_customer
                    "
                    clearable
                    dense
                    no-filter
                    v-model="flt_address"
                    :items="flt_addresses"
                    outlined
                    item-text="name"
                    item-value="id"
                    :label="$t('customers-filter-address')"
                    return-object
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :disabled="
                      loadingPlanner ||
                      updatingPlanner ||
                      loadingFilters ||
                      isLoadingAddress
                    "
                    dense
                    no-filter
                    v-model="flt_operator"
                    :items="flt_operators"
                    outlined
                    item-text="name"
                    item-value="value"
                    :label="$t('customers-filter-operator')"
                    hide-details
                  ></v-select> </v-col
                ><v-col cols="12">
                  <v-select
                    :disabled="
                      loadingPlanner ||
                      updatingPlanner ||
                      loadingFilters ||
                      isLoadingAddress
                    "
                    dense
                    no-filter
                    v-model="flt_day"
                    :items="flt_days"
                    outlined
                    item-text="name"
                    item-value="value"
                    :label="$t('customers-filter-day')"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :disabled="
                      loadingPlanner ||
                      updatingPlanner ||
                      loadingFilters ||
                      isLoadingAddress
                    "
                    dense
                    no-filter
                    v-model="flt_time"
                    :items="flt_times"
                    outlined
                    item-text="name"
                    item-value="value"
                    :label="$t('customers-filter-time')"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>

              <v-toolbar dense color="primary">
                <v-toolbar-title class="pt-1 white--text">{{
                  unassignedDay.name
                }}</v-toolbar-title>
              </v-toolbar>

              <draggable
                animation="0"
                :disabled="updatingPlanner"
                @change="onChange($event, unassignedDay.array)"
                :move="onMove"
                v-model="unassignedDay.array"
                group="shifts"
              >
                <v-sheet
                  color="#eeeeee"
                  elevation="1"
                  class="ma-2"
                  v-for="element in unassignedDay.array"
                  :key="element.id"
                  @mousedown="handleMouseDown($event, element)"
                >
                  <v-container>
                    <v-row dense>
                      <v-col
                        ><span class="font-weight-bold">{{
                          element.name
                        }}</span>
                        <v-tooltip v-if="!element.flexible_day" bottom
                          ><template v-slot:activator="{ on }"
                            ><v-icon class="ml-1" color="red" v-on="on"
                              >mdi-lock</v-icon
                            > </template
                          ><span>{{
                            $t("planner-mng-day-locked")
                          }}</span></v-tooltip
                        ></v-col
                      >
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-icon>mdi-google-maps</v-icon
                        >{{ element.address }}</v-col
                      >
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-icon>mdi-calendar</v-icon
                        >{{ dayToName(element.day) }}</v-col
                      >
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-icon>mdi-account</v-icon
                        >{{
                          $t("planner-mng-operator", {
                            operator: element.operator,
                          })
                        }}</v-col
                      >
                    </v-row>
                    <v-row dense>
                      <v-col
                        ><v-icon>mdi-clock</v-icon
                        >{{ element.shift_start_time.toFormat("HH:mm") }} -
                        {{ element.shift_end_time.toFormat("HH:mm") }}</v-col
                      >
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              :disabled="updatingPlanner"
                              dark
                              class="toolbar-icon"
                              v-on="on"
                              @click="putInPlanner(element)"
                              icon
                              color="green"
                              ><v-icon>mdi-arrow-left</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("planner-mng-put-shift")
                          }}</span></v-tooltip
                        >

                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              :disabled="updatingPlanner"
                              dark
                              class="toolbar-icon"
                              v-on="on"
                              @click="removeShift(element)"
                              icon
                              color="red"
                              ><v-icon>mdi-table-remove</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("planner-mng-remove-shift")
                          }}</span></v-tooltip
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </draggable>
              <v-sheet color="secondary" class="white--text body-1">
                {{ totalDurationOfArray(unassignedDay.array) }}</v-sheet
              >
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { DateTime, Duration } from "luxon";
import plannerManager from "../apis/planner";
import templateManager from "../apis/templates";
import customersManager from "../apis/customers";
import staticData from "../apis/static";
import EditShift from "@/components/EditShift.vue";
import AddPlannerShift from "@/components/AddPlannerShift.vue";
import config from "../config";
import usersManager from "../apis/users";
import shiftManager from "../apis/shifts";

import SetString from "@/components/SetString";
import MessageBox from "@/components/MessageBox.vue";
import SetTemplate from "@/components/SetTemplate.vue";

export default {
  data() {
    return {
      editShiftDialog: false,
      addShiftDialog: false,
      staticData: staticData,
      loadingPlanner: false,
      loadingFilters: false,
      updatingPlanner: false,

      weeks: [
        {
          id: null,
          name: "Seleziona una settimana",
          disabled: true,
        },
      ],
      weeksModes: [
        {
          id: true,
          name: "Future - Aperte",
        },
        {
          id: false,
          name: "Passate - Completate",
        },
      ],

      weeksPeriod: [
        {
          id: null,
          name: "Tutte",
        },
      ],
      reasons: [],
      selectedWeek: null,
      selectedWeeksMode: true,
      selectedWeeksPeriod: null,
      weekState: null,
      weekDays: [],
      workers: [],

      unassignedDay: {
        name: "Turni non assegnati",
        holiday: false,
        pre_holiday: false,
        unassigned: true,
        array: [],
      },

      flt_user: null,
      flt_users: [],
      searchUserInput: null,
      isLoadingUser: false,

      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,

      flt_address: null,
      flt_addresses: [],
      isLoadingAddress: false,

      flt_times: [],
      flt_operator: null,
      flt_day: null,
      flt_time: null,
      flt_days: [
        { name: this.$t("gbl-all-male"), value: null },
        { name: this.$t("day-monday"), value: 0 },
        { name: this.$t("day-tuesday"), value: 1 },
        { name: this.$t("day-wednesday"), value: 2 },
        { name: this.$t("day-thursday"), value: 3 },
        { name: this.$t("day-friday"), value: 4 },
        { name: this.$t("day-saturday"), value: 5 },
        { name: this.$t("day-sunday"), value: 6 },
      ],

      flt_operators: [
        { name: this.$t("gbl-all-male"), value: null },
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "4", value: 4 },
        { name: "5", value: 5 },
        { name: "6", value: 6 },
        { name: "7", value: 7 },
        { name: "8", value: 8 },
        { name: "9", value: 9 },
        { name: "10", value: 10 },
      ],
    };
  },

  components: {
    draggable,
    EditShift,
    AddPlannerShift,
    MessageBox,
    SetString,
    SetTemplate,
  },

  mounted() {
    shiftManager.fetchReasons().then((results) => {
      this.reasons = [...results];
      this.fetchWeeks();
      this.buildWeeksPeriod();
    });
  },

  watch: {
    searchUserInput(val) {
      clearTimeout(this._searchUserInput);
      if (val) {
        this._searchUserInput = setTimeout(() => {
          this.isLoadingUser = true;
          this.fetchFilterUser(val)
            .then((res) => {
              this.flt_users = res.map((x) => {
                x.name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingUser = false;
            })
            .catch(() => {
              this.isLoadingUser = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;
          this.fetchFilterCustomers(val)
            .then((res) => {
              this.flt_customers = res.customers.map((x) => {
                if (x.business_name) x.name = x.business_name;
                else x.name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    flt_customer: {
      handler: function () {
        this.fetchFilterAddresses()
          .then((res) => {
            this.flt_addresses = res;
            for (let x of this.flt_addresses) {
              x.name = x.business_name
                ? x.business_name
                : x.first_name + " " + x.last_name;
            }
            this.fetchWeekUnassignedShifts();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },

    flt_user: {
      handler: function () {
        if (this.selectedWeek && this.flt_user) {
          this.fetchWeekUnassignedShifts();
          this.fetchWeekAssignedShifts(this.selectedWeek, this.flt_user.id);
        } else {
          for (let x of this.weekDays) {
            x.array = [];
          }
        }
      },
    },

    flt_address: {
      handler: function () {
        this.fetchWeekUnassignedShifts();
      },
    },

    flt_operator: {
      handler: function () {
        this.fetchWeekUnassignedShifts();
      },
    },

    flt_day: {
      handler: function () {
        this.fetchWeekUnassignedShifts();
      },
    },

    flt_time: {
      handler: function () {
        this.fetchWeekUnassignedShifts();
      },
    },

    selectedWeeksPeriod: {
      handler: function () {
        this.selectedWeek = null;
        this.fetchWeeks();
      },
    },

    selectedWeeksMode: {
      handler: function (val) {
        this.buildWeeksPeriod();
        this.selectedWeek = null;
        this.selectedWeeksPeriod = this.weeksPeriod[1].id;
        if (val) {
          this.selectedWeeksPeriod = null;
        } else {
          this.selectedWeeksPeriod = this.weeksPeriod[1].id;
        }
        this.fetchWeeks();
      },
    },

    selectedWeek: {
      handler: function () {
        this.fetchWeekState();
        this.fetchWeekUnassignedShifts();
        this.flt_user = null;
      },
    },
  },

  methods: {
    hasException(day) {
      if (this.flt_user.exceptions) {
        for (let x of this.flt_user.exceptions) {
          if (x.shift_day == day.day_date && x.all_day) {
            return x;
          }
        }
      }
      return null;
    },

    getSheetColor(item) {
      if (item.shift_exception) return "red";
      return "#eeeeee";
    },

    getSheetTextColor(item) {
      if (item.shift_exception) return "white";
      return "black";
    },

    hasDayException(date) {
      for (let x of this.flt_user.exceptions) {
        if (x.shift_day == date && x.all_day) {
          return x;
        }
      }
      return null;
    },

    hasTimeException(date, time_start, time_end) {
      for (let x of this.flt_user.exceptions) {
        if (x.shift_day == date && !x.all_day) {
          if (
            DateTime.fromSQL(x.shift_start_time) >= time_start ||
            DateTime.fromSQL(x.shift_end_time) <= time_end
          ) {
            return x;
          }
        }
      }
      return null;
    },

    dayToName(day) {
      return day >= 0 && day <= 6 ? this.staticData.days[day].name : "";
    },

    exportWeekToExcel() {
      usersManager
        .keepAlive()
        .then(() => {
          plannerManager
            .downloadPlannerExcel(this.selectedWeek)
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadTemplate() {
      this.$refs.setTemplate
        .show()
        .then((templateId) => {
          templateManager
            .loadTemplate(templateId, this.selectedWeek)
            .then(() => {
              this.$refs.msgBox
                .show(
                  this.$t("planner-mng-load-template-success-text"),
                  this.$t("planner-mng-load-template-success-title"),
                  true
                )
                .then(() => {
                  this.flt_user = null;
                  this.fetchWeekUnassignedShifts();
                })
                .catch(() => {});
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    addException(element, exception_id) {
      plannerManager
        .addException(element.day_date, this.flt_user.id, exception_id)
        .then((res) => {
          this.fetchWeekAssignedShifts(this.selectedWeek, this.flt_user.id);
          if (!this.flt_user.exceptions) this.flt_user.exceptions = [];
          this.flt_user.exceptions.push(res);
        })
        .catch(() => {});
    },

    removeException(element) {
      plannerManager
        .removeException(element.day_date, this.flt_user.id)
        .then(() => {
          this.fetchWeekAssignedShifts(this.selectedWeek, this.flt_user.id);
          if (this.flt_user.exceptions) {
            for (let i = 0; i < this.flt_user.exceptions.length; i++) {
              if (this.flt_user.exceptions[i].shift_day == element.day_date) {
                this.flt_user.exceptions.splice(i, 1);
                break;
              }
            }
          }
        })
        .catch(() => {});
    },

    editNotes(element) {
      this.$refs.setString
        .show(
          this.$t("planner-mng-edit-notes-title"),
          this.$t("planner-mng-edit-notes-hint"),
          this.$t("planner-mng-edit-notes-text"),
          element.notes,
          null,
          false,
          false
        )
        .then((event) => {
          let notes = event.value;
          plannerManager
            .editNotes(element.id, notes)
            .then(() => {
              element.notes = notes;
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    saveTemplate() {
      this.$refs.setString
        .show(
          this.$t("planner-mng-save-template-title"),
          this.$t("planner-mng-save-template-hint"),
          this.$t("planner-mng-save-template-text"),
          "Nome modello",
          null,
          true,
          false
        )
        .then((event) => {
          let name = event.value;
          templateManager
            .saveTemplate(this.selectedWeek, name)
            .then(() => {
              this.$refs.msgBox
                .show(
                  this.$t("planner-mng-save-template-success-text"),
                  this.$t("planner-mng-save-template-success-title"),
                  true
                )
                .then(() => {})
                .catch(() => {});
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    handleMouseDown(event, item) {
      if (!item.flexible_day) event.preventDefault();
    },

    addToPlanner(item) {
      this.unassignedDay.array.splice(
        this.unassignedDay.array.indexOf(item),
        1
      );

      let day = this.weekDays[item.day];
      day.array.push(item);

      item.updating = true;
      plannerManager
        .updateShiftOnPlanner({
          weekId: this.selectedWeek,
          dayId: day.day,
          customerId: item.customer_id,
          addressId: item.address_id,
          customer_shift_id: item.customer_shift_id,
          workerId: this.flt_user.id,
          shift_start_time: this.toSqlDateTime(item.shift_start_time),

          shift_end_time: this.toSqlDateTime(item.shift_end_time),
        })
        .then((res) => {
          item.id = res;
          item.updating = false;
          this.updatingPlanner = false;
          day.array.sort((a, b) => {
            return a.shift_start_time.valueOf() - b.shift_start_time.valueOf();
          });
        })
        .catch((err) => {
          item.updating = false;
          console.log(err);
          this.updatingPlanner = false;
        });
    },

    removeFromPlanner(item, array) {
      // Remove item from array
      array.splice(array.indexOf(item), 1);
      this.updatingPlanner = true;
      item.updating = true;
      plannerManager
        .putShiftBack({
          weekId: this.selectedWeek,
          workerId: this.flt_user.id,
          customerId: item.customer_id,
          addressId: item.address_id,
          customer_shift_id: item.customer_shift_id,
        })
        .then(() => {
          item.updating = false;
          this.updatingPlanner = false;
        })
        .catch((err) => {
          console.log(err);
          item.updating = false;
          this.updatingPlanner = false;
        });
      this.fetchWeekUnassignedShifts();
    },

    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterUser(filter) {
      return new Promise((resolve, reject) => {
        plannerManager
          .fetchWorkers(this.selectedWeek, filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterAddresses() {
      return new Promise((resolve, reject) => {
        if (this.flt_customer) {
          this.isLoadingAddress = true;
          customersManager
            .fetchAddresses(this.flt_customer.id)
            .then((results) => {
              this.isLoadingAddress = false;
              resolve(results.location_addresses);
            })
            .catch((err) => {
              this.isLoadingAddress = false;
              console.log(err);
              reject(err);
            });
        } else {
          resolve([]);
        }
      });
    },

    shiftConfigured(item) {
      this.editShiftDialog = false;

      item.sourceShift.shift_start_time = DateTime.fromSQL(
        item.shift.shift_start_time
      );
      item.sourceShift.shift_end_time = DateTime.fromSQL(
        item.shift.shift_end_time
      );

      item.array.sort((a, b) => {
        return a.shift_start_time.valueOf() - b.shift_start_time.valueOf();
      });

      this.updatingPlanner = true;
      item.sourceShift.updating = true;
      plannerManager
        .updateShiftOnPlanner({
          weekId: this.selectedWeek,
          dayId: item.sourceShift.day,
          customerId: item.sourceShift.customer_id,
          addressId: item.sourceShift.address_id,
          customer_shift_id: item.sourceShift.customer_shift_id,
          workerId: this.flt_user.id,
          shift_start_time: this.toSqlDateTime(
            item.sourceShift.shift_start_time
          ),

          shift_end_time: this.toSqlDateTime(item.sourceShift.shift_end_time),
        })
        .then((res) => {
          item.id = res;
          this.updatingPlanner = false;
          item.sourceShift.updating = false;
        })
        .catch((err) => {
          console.log(err);
          this.updatingPlanner = false;
          item.sourceShift.updating = false;
        });
    },

    changeShiftTimes(item, array) {
      this.$refs.editShift.setShift(item, array);
      this.editShiftDialog = true;
    },

    mergeShifts(element, array) {
      this.$refs.msgBox
        .show(
          this.$t("planner-mng-merge-shifts-text"),
          this.$t("planner-mng-merge-shifts-title")
        )
        .then(() => {
          let bottomShift = element;
          let topShiftIndex = array.indexOf(bottomShift);
          if (topShiftIndex > 0) {
            let topShift = array[topShiftIndex - 1];
            plannerManager
              .mergeShiftsOnPlanner(bottomShift.id, topShift.id)
              .then(() => {
                this.fetchWeekUnassignedShifts();
                this.fetchWeekAssignedShifts(
                  this.selectedWeek,
                  this.flt_user.id
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch(() => {});
    },

    splitShift(element /*, array*/) {
      this.$refs.msgBox
        .show(
          this.$t("planner-mng-split-shift-text"),
          this.$t("planner-mng-split-shift-title")
        )
        .then(() => {
          plannerManager
            .splitShiftOnPlanner(element.id)
            .then(() => {
              this.fetchWeekUnassignedShifts();
              this.fetchWeekAssignedShifts(this.selectedWeek, this.flt_user.id);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    duplicateShift(element /*, array*/) {
      this.$refs.msgBox
        .show(
          this.$t("planner-mng-duplicate-shift-text"),
          this.$t("planner-mng-duplicate-shift-title")
        )
        .then(() => {
          plannerManager
            .duplicateShiftOnPlanner(element.id)
            .then(() => {
              this.fetchWeekUnassignedShifts();
              this.fetchWeekAssignedShifts(this.selectedWeek, this.flt_user.id);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    createShift(customer) {
      this.$refs.addShift.setCustomer(customer);
      this.addShiftDialog = true;
    },

    onAddShift(shift) {
      this.addShiftDialog = false;

      shift.week_id = this.selectedWeek;
      shift.flexible_day = true;
      shift.flexible_time = true;

      plannerManager.createShiftOnPlanner(shift).then(() => {
        this.fetchWeekUnassignedShifts();
      });
    },

    removeShift(element /*, array*/) {
      this.$refs.msgBox
        .show(
          this.$t("planner-mng-remove-shift-text"),
          this.$t("planner-mng-remove-shift-title")
        )
        .then(() => {
          plannerManager
            .deleteShiftOnPlanner(element.customer_shift_id)
            .then(() => {
              this.fetchWeekUnassignedShifts();
            });
        })
        .catch(() => {});
    },

    putInPlanner(element) {
      plannerManager
        .putShiftOnPlanner(element.customer_shift_id, this.flt_user.id)
        .then(() => {
          this.fetchWeekUnassignedShifts();
          this.fetchWeekAssignedShifts(this.selectedWeek, this.flt_user.id);
        });
    },

    putBackInUnassigned(id) {
      plannerManager.putBackInUnassigned(id).then(() => {
        this.fetchWeekUnassignedShifts();
        this.fetchWeekAssignedShifts(this.selectedWeek, this.flt_user.id);
      });
    },

    toSqlDateTime(dateTime) {
      return dateTime.toFormat("HH:mm:ss");
    },

    fetchWeekUnassignedShifts() {
      this.updatingPlanner = true;
      return new Promise((resolve, reject) => {
        let split_times = this.flt_time ? this.flt_time.split("-") : null;

        plannerManager
          .fetchWeekUnassignedShifts(
            this.selectedWeek,
            this.flt_customer ? this.flt_customer.id : null,
            this.flt_address ? this.flt_address.id : null,
            this.flt_operator,
            this.flt_day,
            split_times
          )
          .then((res) => {
            let shifts = res.shifts;

            this.flt_times = [];
            for (let x of res.times) {
              this.flt_times.push({ name: x.time, value: x.time });
            }

            this.updatingPlanner = false;
            this.unassignedDay.array = shifts.map((x) => {
              return {
                customer_shift_id: x.id,
                customer_id: x.customer_id,
                name: x.addr_business_name
                  ? x.addr_business_name
                  : x.addr_first_name + " " + x.addr_last_name,
                shift_start_time: DateTime.fromSQL(x.shift_start_time),
                shift_end_time: DateTime.fromSQL(x.shift_end_time),
                day: x.shift_day,
                flexible_day: x.flexible_day,
                flexible_time: x.flexible_time,
                shift_operators: x.shift_operators,
                shift_hours: x.shift_hours,
                operator: x.operator,
                address_id: x.address_id,
                address: x.address,
              };
            });
            resolve(shifts);
          })
          .catch((err) => {
            this.updatingPlanner = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchWeekAssignedShifts(week, worker) {
      return new Promise((resolve, reject) => {
        this.updatingPlanner = true;
        plannerManager
          .fetchWeekAssignedShifts(week, worker)
          .then((res) => {
            let numShifts = 0;
            for (let x of this.weekDays) {
              x.array = [];
              x.menu = false;
            }
            for (let x of res) {
              numShifts++;
              x.shift_start_time = DateTime.fromSQL(x.shift_start_time);
              x.shift_end_time = DateTime.fromSQL(x.shift_end_time);

              x.name = x.addr_business_name
                ? x.addr_business_name
                : x.addr_first_name + " " + x.addr_last_name;

              let shift_exception = this.hasTimeException(
                this.weekDays[x.day].day_date,
                x.shift_start_time,
                x.shift_end_time
              );

              if (shift_exception) x.shift_exception = shift_exception;
              this.weekDays[x.day].array.push(x);
              this.weekDays[x.day].exception = this.hasDayException(
                this.weekDays[x.day].day_date
              );
            }
            this.flt_user.shifts_count = numShifts;
            this.updatingPlanner = false;
            resolve(res);
          })
          .catch((err) => {
            this.updatingPlanner = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchWeekState() {
      return new Promise((resolve, reject) => {
        this.updatingPlanner = true;
        plannerManager
          .fetchWeekState(this.selectedWeek)
          .then((res) => {
            this.updatingPlanner = false;
            this.weekState = res;
            this.weekDays = res.weekDays.map((x) => {
              return {
                ...x,
                array: [],
                name: this.dayToName(x.day),
              };
            });
            resolve(res);
          })
          .catch((err) => {
            this.updatingPlanner = false;
            console.log(err);
            reject(err);
          });
      });
    },

    buildWeeksPeriod() {
      this.weeksPeriod = [
        { id: null, name: "Tutte", disabled: this.selectedWeeksMode },
        { id: 1, name: "Mese", disabled: this.selectedWeeksMode },
        { id: 2, name: "3 Mesi", disabled: this.selectedWeeksMode },
        { id: 3, name: "6 Mesi", disabled: this.selectedWeeksMode },
        { id: 4, name: "Anno", disabled: this.selectedWeeksMode },
      ];
      this.selectedWeeksPeriod = this.weeksPeriod[0].id;
    },
    fetchWeeks() {
      this.loadingFilters = true;

      return new Promise((resolve, reject) => {
        plannerManager
          .fetchWeeks({
            selectedWeeksMode: this.selectedWeeksMode,
            selectedWeeksPeriod: this.selectedWeeksPeriod,
          })
          .then((res) => {
            this.weeks = [
              { id: null, name: this.$t("gbl-select-week"), disabled: true },
              ...res.items,
            ];
            this.loadingFilters = false;
            resolve();
          })
          .catch((err) => {
            this.loadingFilters = false;
            console.log(err);
            reject(err);
          });
      });
    },

    onMove(evt /*, originalEvent*/) {
      let destinationArray = evt.relatedContext.list;
      // Find destinationArray in this.weekDays
      let day = this.weekDays.find((x) => x.array === destinationArray);
      if (day && this.hasDayException(day.day_date)) return false;
      return true;
    },

    onChange(evt, array) {
      if (evt.added) {
        let day = this.weekDays.find((x) => x.array === array);
        if (!day) {
          this.updatingPlanner = true;
          evt.added.element.updating = true;
          plannerManager
            .putShiftBack({
              weekId: this.selectedWeek,
              workerId: this.flt_user.id,
              customerId: evt.added.element.customer_id,
              addressId: evt.added.element.address_id,
              customer_shift_id: evt.added.element.customer_shift_id,
            })
            .then(() => {
              evt.added.element.id = null;
              evt.added.element.updating = false;
              this.updatingPlanner = false;
              array.sort((a, b) => {
                return (
                  // compare a.name
                  a.name.localeCompare(b.name) ||
                  // if a.name is the same, compare a.shift_start_time
                  a.shift_start_time.valueOf() - b.shift_start_time.valueOf()
                );
              });
            });
        } else {
          if (this.hasDayException(day.day_date)) {
            this.fetchWeekAssignedShifts(this.selectedWeek, this.flt_user.id);
            this.fetchWeekUnassignedShifts();
          } else {
            this.updatingPlanner = true;
            evt.added.element.updating = true;
            plannerManager
              .updateShiftOnPlanner({
                weekId: this.selectedWeek,
                dayId: day.day,
                customerId: evt.added.element.customer_id,
                addressId: evt.added.element.address_id,
                customer_shift_id: evt.added.element.customer_shift_id,
                workerId: this.flt_user.id,
                shift_start_time: this.toSqlDateTime(
                  evt.added.element.shift_start_time
                ),

                shift_end_time: this.toSqlDateTime(
                  evt.added.element.shift_end_time
                ),
              })
              .then((res) => {
                evt.added.element.id = res;
                evt.added.element.updating = false;
                this.updatingPlanner = false;
                array.sort((a, b) => {
                  return (
                    a.shift_start_time.valueOf() - b.shift_start_time.valueOf()
                  );
                });
              })
              .catch((err) => {
                evt.added.element.updating = false;
                console.log(err);
                this.updatingPlanner = false;
              });
          }
        }
      }
    },

    totalDurationOfArray(arr) {
      if (!arr) return "00:00";
      let dur = Duration.fromObject({
        minutes: arr.reduce((acc, curr) => {
          return (
            acc +
            curr.shift_end_time.diff(curr.shift_start_time, "minutes").minutes
          );
        }, 0),
      });
      return dur.toFormat("hh:mm");
    },
    ordinaryDay(day) {
      return !day.holiday && !day.pre_holiday;
    },
  },

  computed: {
    getTotalHours() {
      return this.totalDuration.total;
    },

    getRegularHours() {
      return this.totalDuration.ordinary;
    },

    getContractHours() {
      return this.flt_user ? this.flt_user.contract_hours.toString() : "00:00";
    },

    getPreHolidayHours() {
      return this.totalDuration.preHoliday;
    },

    getHolidayHours() {
      return this.totalDuration.holiday;
    },

    getTotalDays() {
      return this.totalDuration.totalDays;
    },

    getAvailableWork() {
      // convert seconds in weekState.availableWork in D:HH:mm:ss
      if (!this.weekState) return "--:--:--";
      return Duration.fromObject({
        seconds: this.weekState.availableWork,
      }).toFormat("d(g) hh:mm:ss");
    },
    getAvailableWorkCount() {
      // convert seconds in weekState.availableWork in D:HH:mm:ss
      if (!this.weekState) return "Totale personale: - ";
      return "Totale personale: " + this.weekState.availableWorkCount;
    },

    getTotalCustomersShiftsTime() {
      if (!this.weekState) return "--:--:--";
      return "--:--:--";
    },

    getTotalFreeShiftsTime() {
      if (!this.weekState) return "--:--:--";
      return "--:--:--";
    },

    getTotalFreeShifts() {
      if (!this.weekState) return "Totale turni: - ";
      return "Totale turni: " + this.weekState.totalShifts;
    },

    getTotalCustomers() {
      if (!this.weekState) return "Totale clienti: - ";
      return "Totale clienti: " + this.weekState.totalCustomers;
    },

    getTotalCustomersTime() {
      if (!this.weekState) return "--:--:--";
      return "--:--:--";
    },

    getCustomersPendingShifts() {
      if (!this.weekState) return "Totale turni non allocati: - ";
      return "Totale turni non allocati: " + this.weekState.pendingShifts
        ? this.weekState.pendingShifts
        : "-";
    },

    getCustomersPendingShiftsTime() {
      if (!this.weekState) return "--:--:--";
      return "--:--:--";
    },

    totalDuration() {
      if (!this.flt_user) return Duration.fromObject({}).toFormat("hh:mm");

      let totalMinutes = this.weekDays
        .filter((x) => !x.unassigned)
        .map((day) => {
          return day.array.reduce((acc, curr) => {
            return (
              acc +
              curr.shift_end_time.diff(curr.shift_start_time, "minutes").minutes
            );
          }, 0);
        })
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);
      let totalOrdinary = this.weekDays
        .map((day) => {
          return day.array.reduce((acc, curr) => {
            return (
              acc +
              (this.ordinaryDay(day)
                ? curr.shift_end_time.diff(curr.shift_start_time, "minutes")
                    .minutes
                : 0)
            );
          }, 0);
        })
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);
      let totalPreHoliday = this.weekDays
        .map((day) => {
          return day.array.reduce((acc, curr) => {
            return (
              acc +
              (day.pre_holiday
                ? curr.shift_end_time.diff(curr.shift_start_time, "minutes")
                    .minutes
                : 0)
            );
          }, 0);
        })
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);
      let totalHoliday = this.weekDays
        .map((day) => {
          return day.array.reduce((acc, curr) => {
            return (
              acc +
              (day.holiday
                ? curr.shift_end_time.diff(curr.shift_start_time, "minutes")
                    .minutes
                : 0)
            );
          }, 0);
        })
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);

      let totalDays = this.weekDays.filter((x) => x.array.length > 0).length;
      return {
        total: Duration.fromObject({ minutes: totalMinutes }).toFormat("hh:mm"),
        ordinary: Duration.fromObject({ minutes: totalOrdinary }).toFormat(
          "hh:mm"
        ),
        preHoliday: Duration.fromObject({ minutes: totalPreHoliday }).toFormat(
          "hh:mm"
        ),
        holiday: Duration.fromObject({ minutes: totalHoliday }).toFormat(
          "hh:mm"
        ),
        totalDays: totalDays.toString(),
      };
    },
  },
};
</script>

<style>
.display-none {
  display: none;
}
</style>
