<template>
  <v-card class="ma-4">
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" md="3" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-calendar"
            title="Ore totali"
            value="..."
          />
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3">
          <base-material-stats-card
            color="primary"
            icon="mdi-calendar"
            title="Ore svolte"
            value="..."
          />
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3">
          <base-material-stats-card
            color="secondary"
            icon="mdi-calendar"
            title="Ore da svolgere"
            value="..."
          />
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3">
          <base-material-stats-card
            color="warning"
            icon="mdi-calendar"
            title="Ore non svolte"
            value="..."
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <base-material-stats-card
            color="warning"
            icon="mdi-calendar"
            title="Eccezioni"
            value="..."
          />
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <base-material-stats-card
            color="success"
            icon="mdi-signature-freehand"
            title="Utenti con eccezioni"
            value="..."
          /> </v-col
      ></v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loadingPlanner: false,
      loadingFilters: false,

      selectedWeek: null,
      selectedWeeksMode: true,
      selectedWeeksPeriod: null,
    };
  },
};
</script>
