<template>
  <div class="ma-4">
    <v-card class="ma-4">
      <v-toolbar color="primary">
        <v-icon color="white" large class="mr-2">mdi-format-list-text</v-icon>
        <v-toolbar-title class="white--text">
          {{ $t("planner-mng-title") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-week')"
              outlined
              :disabled="loadingFilters"
              item-text="name"
              item-value="id"
              dense
              hide-details
              :items="weeks"
              v-model="selectedWeek"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-week-mode')"
              outlined
              :disabled="loadingFilters"
              item-text="name"
              item-value="id"
              dense
              hide-details
              :items="weeksModes"
              v-model="selectedWeeksMode"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-week-time')"
              outlined
              :disabled="loadingFilters"
              item-text="name"
              item-value="id"
              dense
              hide-details
              :items="weeksPeriod"
              v-model="selectedWeeksPeriod"
            ></v-select>
          </v-col>

          <v-col>
            <v-autocomplete
              :disabled="loadingPlanner || loadingFilters || !this.selectedWeek"
              clearable
              dense
              no-filter
              v-model="flt_user"
              :items="flt_users"
              :loading="isLoadingUser"
              :search-input.sync="searchUserInput"
              outlined
              item-text="name"
              item-value="id"
              :label="$t('customers-filter-user')"
              return-object
              hide-details
              ><template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <div class="v-list-item__title">{{ data.item.name }}</div>
                <v-icon v-if="data.item.shifts_count > 0" color="green"
                  >mdi-circle</v-icon
                >
                <v-icon
                  v-if="data.item.exceptions && data.item.exceptions.length > 0"
                  color="orange"
                  >mdi-bed-clock</v-icon
                >
              </template></v-autocomplete
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-progress-circular
              v-if="loadingPlanner"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-row dense v-if="!loadingPlanner && selectedWeek && flt_user">
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="primary"
              icon="mdi-calendar"
              title="Ore totali"
              :value="getTotalHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="primary"
              icon="mdi-calendar"
              title="Ore ordinarie"
              :value="getRegularHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="secondary"
              icon="mdi-calendar"
              title="Ore prefestivo"
              :value="getPreHolidayHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="warning"
              icon="mdi-calendar"
              title="Ore festivo"
              :value="getHolidayHours"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="warning"
              icon="mdi-calendar"
              title="Giorni"
              :value="getTotalDays"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <base-material-stats-card
              color="success"
              icon="mdi-signature-freehand"
              title="Ore da contratto"
              :value="getContractHours"
            />
          </v-col>
        </v-row>

        <v-row v-if="selectedWeek">
          <v-col>
            <v-data-table
              dense
              :items-per-page="options.itemsPerPage"
              :must-sort="true"
              :headers="headers"
              :items="shifts"
              :options.sync="options"
              :server-items-length="totalShifts"
              :loading="loadingPlanner"
              :footer-props="dataTableFooterOptions"
              item-key="id"
            >
              <template v-slot:item.operator_name="{ item }">
                {{ item.first_name + " " + item.last_name }}
              </template>
              <template v-slot:item.address="{ item }">
                {{
                  item.addr_business_name
                    ? item.addr_business_name
                    : item.addr_first_name + " " + item.addr_last_name
                }}
              </template>
              <template v-slot:item.day="{ item }">
                {{ days[item.day].name }}
              </template>
              <template v-slot:item.day_date="{ item }">
                {{ item.day_date | toLocaleDate }}
              </template>
              <template v-slot:item.actions="{ item }"> </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Duration } from "luxon";
import plannerManager from "../apis/planner";
import staticData from "../apis/static";
import config from "../config";
import customersManager from "../apis/customers";

export default {
  data() {
    return {
      options: {
        sortBy: ["c1.business_name", "c1.last_name", "c1.first_name"],
        sortDesc: [true, true, true],
        itemsPerPage: 50,
        page: 1,
      },
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      staticData: staticData,
      loadingPlanner: false,
      loadingFilters: false,
      shifts: [],
      totalShifts: 0,
      weeks: [
        {
          id: null,
          name: "Seleziona una settimana",
          disabled: true,
        },
      ],
      weeksModes: [
        {
          id: true,
          name: "Future - Aperte",
        },
        {
          id: false,
          name: "Passate - Completate",
        },
      ],

      weeksPeriod: [
        {
          id: null,
          name: "Tutte",
        },
      ],
      reasons: [],
      selectedWeek: null,
      selectedWeeksMode: true,
      selectedWeeksPeriod: null,
      weekState: null,
      weekDays: [],
      workers: [],
      headers: [],
      flt_user: null,
      flt_users: [],
      searchUserInput: null,
      isLoadingUser: false,
      days: [...staticData.days],
      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,

      flt_address: null,
      flt_addresses: [],
      isLoadingAddress: false,
    };
  },

  components: {},

  mounted() {
    this.headers = [
      {
        text: this.$t("customers-tbl-business-name"),
        align: "center",
        sortable: true,
        value: "operator_name",
      },
      {
        text: this.$t("customers-tbl-address"),
        align: "center",
        sortable: true,
        value: "address",
      },

      {
        text: this.$t("customers-tbl-shift-day"),
        align: "center",
        sortable: true,
        value: "day",
      },
      {
        text: this.$t("customers-tbl-shift-date"),
        align: "center",
        sortable: true,
        value: "day_date",
      },
      {
        text: this.$t("customers-tbl-shift-hour"),
        align: "center",
        sortable: true,
        value: "shift_start_time",
      },
      {
        text: this.$t("customers-tbl-shift-hour"),
        align: "center",
        sortable: true,
        value: "shift_end_time",
      },

      { text: "", align: "right", sortable: false, value: "actions" },
    ];
  },

  watch: {
    searchUserInput(val) {
      clearTimeout(this._searchUserInput);
      if (val) {
        this._searchUserInput = setTimeout(() => {
          this.isLoadingUser = true;
          this.fetchFilterUser(val)
            .then((res) => {
              this.flt_users = res.map((x) => {
                x.name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingUser = false;
            })
            .catch(() => {
              this.isLoadingUser = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;
          this.fetchFilterCustomers(val)
            .then((res) => {
              this.flt_customers = res.customers.map((x) => {
                if (x.business_name) x.name = x.business_name;
                else x.name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    options: {
      handler: function () {
        this.fetchShifts();
      },
      deep: true,
    },

    flt_customer: {
      handler: function () {
        this.fetchFilterAddresses()
          .then((res) => {
            this.flt_addresses = res;
            for (let x of this.flt_addresses) {
              x.name = x.business_name
                ? x.business_name
                : x.first_name + " " + x.last_name;
            }
            this.fetchShifts();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },

    flt_user: {
      handler: function () {
        this.fetchShifts();
      },
    },

    selectedWeeksPeriod: {
      handler: function () {
        this.shifts = [];
        this.totalShifts = 0;
        this.selectedWeek = null;
        this.fetchWeeks();
      },
    },

    selectedWeeksMode: {
      handler: function (val) {
        this.buildWeeksPeriod();
        this.selectedWeek = null;
        this.selectedWeeksPeriod = this.weeksPeriod[1].id;
        if (val) {
          this.selectedWeeksPeriod = null;
        } else {
          this.selectedWeeksPeriod = this.weeksPeriod[1].id;
        }
        this.fetchWeeks();
      },
    },

    selectedWeek: {
      handler: function () {
        this.fetchShifts();
        this.flt_user = null;
      },
    },
  },

  methods: {
    fetchShifts() {
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        ctx.selectedWeek = this.selectedWeek;
        ctx.userId = this.flt_user ? this.flt_user.id : null;

        plannerManager
          .fetchShifts(ctx)
          .then((results) => {
            this.shifts = results.shifts;
            this.totalShifts = results.totalShifts;
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterUser(filter) {
      return new Promise((resolve, reject) => {
        plannerManager
          .fetchWorkers(this.selectedWeek, filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterAddresses() {
      return new Promise((resolve, reject) => {
        if (this.flt_customer) {
          this.isLoadingAddress = true;
          customersManager
            .fetchAddresses(this.flt_customer.id)
            .then((results) => {
              this.isLoadingAddress = false;
              resolve(results.location_addresses);
            })
            .catch((err) => {
              this.isLoadingAddress = false;
              console.log(err);
              reject(err);
            });
        } else {
          resolve([]);
        }
      });
    },

    toSqlDateTime(dateTime) {
      return dateTime.toFormat("HH:mm:ss");
    },

    buildWeeksPeriod() {
      this.weeksPeriod = [
        { id: null, name: "Tutte", disabled: this.selectedWeeksMode },
        { id: 1, name: "Mese", disabled: this.selectedWeeksMode },
        { id: 2, name: "3 Mesi", disabled: this.selectedWeeksMode },
        { id: 3, name: "6 Mesi", disabled: this.selectedWeeksMode },
        { id: 4, name: "Anno", disabled: this.selectedWeeksMode },
      ];
      this.selectedWeeksPeriod = this.weeksPeriod[0].id;
    },

    fetchWeeks() {
      this.loadingFilters = true;

      return new Promise((resolve, reject) => {
        plannerManager
          .fetchWeeks({
            selectedWeeksMode: this.selectedWeeksMode,
            selectedWeeksPeriod: this.selectedWeeksPeriod,
          })
          .then((res) => {
            this.weeks = [
              { id: null, name: this.$t("gbl-select-week"), disabled: true },
              ...res.items,
            ];
            this.loadingFilters = false;
            resolve();
          })
          .catch((err) => {
            this.loadingFilters = false;
            console.log(err);
            reject(err);
          });
      });
    },

    totalDurationOfArray(arr) {
      if (!arr) return "00:00";
      let dur = Duration.fromObject({
        minutes: arr.reduce((acc, curr) => {
          return (
            acc +
            curr.shift_end_time.diff(curr.shift_start_time, "minutes").minutes
          );
        }, 0),
      });
      return dur.toFormat("hh:mm");
    },
    ordinaryDay(day) {
      return !day.holiday && !day.pre_holiday;
    },
  },

  computed: {
    getTotalHours() {
      return this.totalDuration.total;
    },

    getRegularHours() {
      return this.totalDuration.ordinary;
    },

    getContractHours() {
      return this.flt_user ? this.flt_user.contract_hours.toString() : "00:00";
    },

    getPreHolidayHours() {
      return this.totalDuration.preHoliday;
    },

    getHolidayHours() {
      return this.totalDuration.holiday;
    },

    getTotalDays() {
      return this.totalDuration.totalDays;
    },

    getAvailableWork() {
      // convert seconds in weekState.availableWork in D:HH:mm:ss
      if (!this.weekState) return "--:--:--";
      return Duration.fromObject({
        seconds: this.weekState.availableWork,
      }).toFormat("d(g) hh:mm:ss");
    },
    getAvailableWorkCount() {
      // convert seconds in weekState.availableWork in D:HH:mm:ss
      if (!this.weekState) return "Totale personale: - ";
      return "Totale personale: " + this.weekState.availableWorkCount;
    },

    getTotalCustomersShiftsTime() {
      if (!this.weekState) return "--:--:--";
      return "--:--:--";
    },

    getTotalFreeShiftsTime() {
      if (!this.weekState) return "--:--:--";
      return "--:--:--";
    },

    getTotalFreeShifts() {
      if (!this.weekState) return "Totale turni: - ";
      return "Totale turni: " + this.weekState.totalShifts;
    },

    getTotalCustomers() {
      if (!this.weekState) return "Totale clienti: - ";
      return "Totale clienti: " + this.weekState.totalCustomers;
    },

    getTotalCustomersTime() {
      if (!this.weekState) return "--:--:--";
      return "--:--:--";
    },

    getCustomersPendingShifts() {
      if (!this.weekState) return "Totale turni non allocati: - ";
      return "Totale turni non allocati: " + this.weekState.pendingShifts
        ? this.weekState.pendingShifts
        : "-";
    },

    getCustomersPendingShiftsTime() {
      if (!this.weekState) return "--:--:--";
      return "--:--:--";
    },

    totalDuration() {
      if (!this.flt_user) return Duration.fromObject({}).toFormat("hh:mm");

      let totalMinutes = this.weekDays
        .filter((x) => !x.unassigned)
        .map((day) => {
          return day.array.reduce((acc, curr) => {
            return (
              acc +
              curr.shift_end_time.diff(curr.shift_start_time, "minutes").minutes
            );
          }, 0);
        })
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);
      let totalOrdinary = this.weekDays
        .map((day) => {
          return day.array.reduce((acc, curr) => {
            return (
              acc +
              (this.ordinaryDay(day)
                ? curr.shift_end_time.diff(curr.shift_start_time, "minutes")
                    .minutes
                : 0)
            );
          }, 0);
        })
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);
      let totalPreHoliday = this.weekDays
        .map((day) => {
          return day.array.reduce((acc, curr) => {
            return (
              acc +
              (day.pre_holiday
                ? curr.shift_end_time.diff(curr.shift_start_time, "minutes")
                    .minutes
                : 0)
            );
          }, 0);
        })
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);
      let totalHoliday = this.weekDays
        .map((day) => {
          return day.array.reduce((acc, curr) => {
            return (
              acc +
              (day.holiday
                ? curr.shift_end_time.diff(curr.shift_start_time, "minutes")
                    .minutes
                : 0)
            );
          }, 0);
        })
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);

      let totalDays = this.weekDays.filter((x) => x.array.length > 0).length;
      return {
        total: Duration.fromObject({ minutes: totalMinutes }).toFormat("hh:mm"),
        ordinary: Duration.fromObject({ minutes: totalOrdinary }).toFormat(
          "hh:mm"
        ),
        preHoliday: Duration.fromObject({ minutes: totalPreHoliday }).toFormat(
          "hh:mm"
        ),
        holiday: Duration.fromObject({ minutes: totalHoliday }).toFormat(
          "hh:mm"
        ),
        totalDays: totalDays.toString(),
      };
    },
  },
};
</script>

<style>
.display-none {
  display: none;
}
</style>
