<template>
  <v-dialog overlay-opacity="0.6" max-width="800" :value="openDialog">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col
                  align="center"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("templates-mng-select-template") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <v-select
                    outlined
                    dense
                    hide-details
                    v-model="templateId"
                    item-text="name"
                    item-value="id"
                    :items="templates"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-select>
                </ValidationProvider>
              </ValidationObserver>
            </v-card-text>
            <v-btn
              :disabled="!templateId"
              width="120"
              @click="confirm()"
              color="primary"
              >{{ $t("gbl-ok") }}</v-btn
            >
            <v-btn width="120" @click="dismiss()" color="secondary">{{
              $t("gbl-cancel")
            }}</v-btn>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import templatesManager from "@/apis/templates";

export default {
  data() {
    return {
      openDialog: false,
      templateId: null,
      resolve: null,
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  methods: {
    fetchTemplates() {
      return new Promise((resolve, reject) => {
        templatesManager
          .fetchTemplates({})
          .then((res) => {
            this.templates = [
              {
                id: null,
                name: this.$t("gbl-select-template"),
                disabled: true,
              },
              ...res.templates,
            ];
            resolve(this.templates);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    show() {
      this.templateId = null;
      return new Promise((resolve, reject) => {
        this.fetchTemplates()
          .then(() => {
            this.openDialog = true;
            this.resolve = resolve;
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    hide() {
      this.openDialog = false;
    },

    confirm() {
      this.resolve(this.templateId);
      this.openDialog = false;
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>
