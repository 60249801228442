a
<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-clock</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("shiftadd-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form class="mt-5" ref="weekeditor-editor-form">
              <v-row dense
                ><v-col
                  ><span class="font-weight-bold">
                    {{
                      customer
                        ? customer.business_name
                          ? customer.business_name
                          : customer.first_name + " " + customer.last_name
                        : ""
                    }}</span
                  ></v-col
                ></v-row
              >
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row dense justify="center">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        :disabled="
                          fetchingData || !shift.customer_id || isLoadingAddress
                        "
                        dense
                        no-filter
                        v-model="shift.address_id"
                        :items="flt_addresses"
                        outlined
                        item-text="name"
                        item-value="id"
                        :label="$t('customers-filter-address')"
                      ></v-select
                    ></ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="6">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        :label="$t('shifteditor-day')"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        v-model="shift.shift_day"
                        :items="days"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6">
                    <ValidationProvider
                      rules="required|numeric|min_value:1"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="shift.operator"
                        :label="$t('shifteditor-operator')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        type="time"
                        outlined
                        dense
                        v-model="shift.shift_start_time"
                        :label="$t('shifteditor-time-start')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        type="time"
                        outlined
                        dense
                        v-model="shift.shift_end_time"
                        :label="$t('shifteditor-time-end')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-alert
                      dense
                      v-if="shift.shift_start_time > shift.shift_end_time"
                      type="error"
                      >{{ $t("shifteditor-time-error") }}</v-alert
                    >
                  </v-col>
                </v-row>

                <v-divider />

                <div style="height: 10px" />

                <v-row dense>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="
                        invalid ||
                        !validated ||
                        fetchingData ||
                        shift.shift_start_time > shift.shift_end_time
                      "
                      v-on:click="confirm()"
                      color="orange"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="primary">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import staticData from "../apis/static";
import customersManager from "../apis/customers";

export default {
  data() {
    return {
      fetchingData: false,
      customer: null,
      days: [
        { id: null, disabled: true, name: this.$t("gbl-select-day") },
        ...staticData.days,
      ],
      shift: {
        customer_id: null,
        shift_start_time: null,
        shift_end_time: null,
        shift_day: null,
        operator: 1,
        address_id: null,
      },
      flt_addresses: [],
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {},

  methods: {
    setCustomer(customer) {
      this.shift.customer_id = customer.id;
      this.shift.address_id = null;
      this.shift.shift_start_time = null;
      this.shift.shift_end_time = null;
      this.customer = customer;
      this.flt_addresses = [
        {
          id: null,
          disabled: true,
          name: this.$t("customers-filter-select-address"),
        },
      ];
      this.fetchFilterAddressess(this.shift.customer_id)
        .then((res) => {
          this.flt_addresses = res.map((x) => {
            x.name = x.business_name
              ? x.business_name
              : x.first_name + " " + x.last_name;
            x.name += " - " + x.address;

            return x;
          });
          this.flt_addresses = [
            {
              id: null,
              disabled: true,
              name: this.$t("customers-filter-select-address"),
            },
            ...this.flt_addresses,
          ];
        })
        .catch(() => {});
    },

    fetchFilterAddressess(customer_id) {
      this.isLoadingAddress = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchAddresses(customer_id)
          .then((results) => {
            this.isLoadingAddress = false;
            resolve(results.location_addresses);
          })
          .catch((err) => {
            this.isLoadingAddress = false;
            console.log(err);
            reject(err);
          });
      });
    },

    confirm: function () {
      this.$emit("shiftConfigured", this.shift);
    },

    closeDialog: function () {
      this.cancel();
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
      this.resetModal();
    },

    resetModal() {
      this.shift = {
        customer_id: null,
      };
      this.customer = null;
    },
  },
};
</script>
