<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-clock</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("shiftedit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form class="mt-5" ref="weekeditor-editor-form">
              <div class="body-1 mb-2">
                {{ $t("shifteditor-pick-day") }}
              </div>

              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row dense align="center">
                  <v-col cols="6">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        type="time"
                        outlined
                        dense
                        v-model="shift.shift_start_time"
                        :label="$t('shifteditor-time-start')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="6">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        type="time"
                        outlined
                        dense
                        v-model="shift.shift_end_time"
                        :label="$t('shifteditor-time-end')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-alert
                      dense
                      v-if="shift.shift_start_time > shift.shift_end_time"
                      type="error"
                      >{{ $t("shifteditor-time-error") }}</v-alert
                    >
                  </v-col>
                </v-row>

                <v-divider />

                <div style="height: 10px" />

                <v-row dense>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="
                        invalid ||
                        !validated ||
                        fetchingData ||
                        shift.shift_start_time > shift.shift_end_time
                      "
                      v-on:click="confirm()"
                      color="orange"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="primary">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      fetchingData: false,
      shift: {
        id: null,
        shift_end_time: null,
        shift_start_time: null,
      },
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {},

  methods: {
    setShift(shift, array) {
      this.shift = { ...shift };
      this.shift.shift_start_time =
        this.shift.shift_start_time.toFormat("HH:mm");
      this.shift.shift_end_time = this.shift.shift_end_time.toFormat("HH:mm");
      this.sourceShift = shift;
      this.array = array;
    },
    confirm: function () {
      this.$emit("shiftConfigured", {
        shift: this.shift,
        sourceShift: this.sourceShift,
        array: this.array,
      });
    },

    closeDialog: function () {
      this.cancel();
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
      this.resetModal();
    },

    resetModal() {
      this.shift = {
        id: null,
        shift_day: null,
        shift_end_time: null,
        shift_start_time: null,
        user_id: null,
      };
      this.array = null;
    },
  },
};
</script>
